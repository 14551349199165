exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-site-management-tools-js": () => import("./../../../src/pages/site-management-tools.js" /* webpackChunkName: "component---src-pages-site-management-tools-js" */),
  "component---src-pages-site-management-tools-minify-css-js": () => import("./../../../src/pages/site-management-tools/minify-css.js" /* webpackChunkName: "component---src-pages-site-management-tools-minify-css-js" */),
  "component---src-pages-site-management-tools-minify-html-js": () => import("./../../../src/pages/site-management-tools/minify-html.js" /* webpackChunkName: "component---src-pages-site-management-tools-minify-html-js" */),
  "component---src-pages-site-management-tools-minify-js-js": () => import("./../../../src/pages/site-management-tools/minify-js.js" /* webpackChunkName: "component---src-pages-site-management-tools-minify-js-js" */),
  "component---src-pages-site-management-tools-unminify-css-js": () => import("./../../../src/pages/site-management-tools/unminify-css.js" /* webpackChunkName: "component---src-pages-site-management-tools-unminify-css-js" */),
  "component---src-pages-site-management-tools-unminify-html-js": () => import("./../../../src/pages/site-management-tools/unminify-html.js" /* webpackChunkName: "component---src-pages-site-management-tools-unminify-html-js" */),
  "component---src-pages-site-management-tools-unminify-js-js": () => import("./../../../src/pages/site-management-tools/unminify-js.js" /* webpackChunkName: "component---src-pages-site-management-tools-unminify-js-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-text-analysis-grammar-checker-js": () => import("./../../../src/pages/text-analysis/grammar-checker.js" /* webpackChunkName: "component---src-pages-text-analysis-grammar-checker-js" */),
  "component---src-pages-text-analysis-js": () => import("./../../../src/pages/text-analysis.js" /* webpackChunkName: "component---src-pages-text-analysis-js" */),
  "component---src-pages-text-analysis-lowercase-to-uppercase-js": () => import("./../../../src/pages/text-analysis/lowercase-to-uppercase.js" /* webpackChunkName: "component---src-pages-text-analysis-lowercase-to-uppercase-js" */),
  "component---src-pages-text-analysis-md-5-generator-js": () => import("./../../../src/pages/text-analysis/md5-generator.js" /* webpackChunkName: "component---src-pages-text-analysis-md-5-generator-js" */),
  "component---src-pages-text-analysis-punctuation-checker-js": () => import("./../../../src/pages/text-analysis/punctuation-checker.js" /* webpackChunkName: "component---src-pages-text-analysis-punctuation-checker-js" */),
  "component---src-pages-text-analysis-reverse-text-js": () => import("./../../../src/pages/text-analysis/reverse-text.js" /* webpackChunkName: "component---src-pages-text-analysis-reverse-text-js" */),
  "component---src-pages-text-analysis-speech-to-text-js": () => import("./../../../src/pages/text-analysis/speech-to-text.js" /* webpackChunkName: "component---src-pages-text-analysis-speech-to-text-js" */),
  "component---src-pages-text-analysis-spell-check-js": () => import("./../../../src/pages/text-analysis/spell-check.js" /* webpackChunkName: "component---src-pages-text-analysis-spell-check-js" */),
  "component---src-pages-text-analysis-text-to-speech-js": () => import("./../../../src/pages/text-analysis/text-to-speech.js" /* webpackChunkName: "component---src-pages-text-analysis-text-to-speech-js" */),
  "component---src-pages-text-analysis-upper-to-lower-case-js": () => import("./../../../src/pages/text-analysis/upper-to-lower-case.js" /* webpackChunkName: "component---src-pages-text-analysis-upper-to-lower-case-js" */),
  "component---src-pages-text-analysis-words-count-js": () => import("./../../../src/pages/text-analysis/words-count.js" /* webpackChunkName: "component---src-pages-text-analysis-words-count-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

